<template>
  <div>
    <b-card >
      <b-row class="text-center">
        <b-col sm="12" md="3">
          <img
            width="25%"
            style="border-radius:50%;"
            src="@/assets/user_1.png"
            alt=""
          />

          <b-card-text>
            <p class="school32 text-center">Ажзий <br />(1864 -1927 )</p></b-card-text
          >
        </b-col>
        <b-col sm="12" md="6">
          <b-card-text>
          <p class="TextBlack17">
            Туркистонда жадидчилик ҳаракатининг йирик вакилларидан бири
            Саидаҳмад Ҳасанхўжа ўғли Сиддиқий — Ажзий 1864 йилда Самарқанд
            вилоятининг Жомбой тумани, Ҳалвойи қишлоғида ҳунарманд оиласида
            таваллуд топди. 1880 йиллардан эътиборан ўзи ҳам анъанавий усулдаги
            шеърлар машқ қила бошлади. Аммо, унинг маърифатчилик фаолияти ва
            ижоди ўз даврининг буюк кишиларидан бири сифатида эътироф этилишига
            олиб келди. Сиддиқий – Ажзий 1901 йилда ҳаж сафарига отланади.
            Мазкур саёҳат давомида бир қатор Шарқ мамлакатларида бўлиб, Ислом
            дунёсидаги янгиликлар билан танишди, бир неча муддат Жиддада Русия
            элчихонасида таржимонлик қилди. Сўнг Москва, Петербург шаҳарларини
            айланиб, Тифлисга ўтади. Ниҳоят Самарқандга қайтиб, 1903 йил Ҳалвойи
            қишлоғида янги мактаб очади. Шоир кейинги бутун ҳаётини ана шу
            муқаддас ишга сарф этди. Ўзи очган мактабда табиий фанлар, араб
            ҳамда рус тилларидан сабоқ берди, дарсликлар тузди. У миллий уйғониш
            даврининг барча шоирлари сингари кенг оммани маърифатга бошлаш, ўз
            ҳақ-ҳуқуқларини англашга ўргатишни адабиётнинг бош мавзуи деб билди
            унга ўз ҳаётини бағишлади. Шу билан бир вақтда, Сиддиқий Ажзий
            вақтли матбуот нашрларида ҳам фаол иштирок этди. Жумладан, Ватан,
            илм-маърифатга бағишланган мақолалари билан Қозон, Оренбург ва
            Кавказдаги нашрларда қатнашди. Сиддиқий Ажзий 1917 йил февраль
            инқилобидан кейинги ижтимоий ҳаракатларда ҳам бевосита иштирок этди.
            Жумладан, 1918—21 йилларда Вилоят Адлия комиссари лавозимида фаолият
            кўрсатди. 1922 йилдан яна ўқитувчиликка қайтди. Самарқандда ўзбек ва
            тожик тилларидаги «Машраб», «Мулла Мушфиқий» каби ҳажвий
            журналларни, «Зарафшон» газетасини чиқаришда фаол иштирок этди.
            «Майна», «Гинагина», «Шашпар», «Тир», «Гумном», «Олмос» имзолари
            билан ҳажвий асарлар ёзди. Сиддиқий 1926 йили қаттиқ касалланиб,
            1927 йилнинг июл ойида Самарқандда вафот этди. ХХ аср бошларида етук
            шоир, таржимон ва моҳир педагог, усули жадид мактабларининг
            ташкилотчиларидан бири сифатида фаолият кўрсатган маърифатпарвар
            Сиддиқий Ажзийнинг кўп қиррали фаолияти 1920-йиллардаёқ
            тадқиқотчилар эътиборини тортди. ХХ аср бошларида Туркистонда
            фаолият юритган, жадидчилик ҳаракатининг йирик намоёндаларидан бири
            Саидаҳмад Сиддиқий-Ажзий фаолияти ўлкада маориф тараққиёти, янги
            усул мактабларининг вужудга келиши ва ривожланишида муҳим аҳамиятга
            эга бўлди. Унинг саъй-ҳаракатлари натижасида Самарқанд вилоятининг
            Жомбой туманидаги Ҳолвойи қишлоғида янги усул мактабига асос
            солинди. Мазкур даргоҳ нафақат ўз қишлоғи ва вилоятда, балки, бутун
            Туркистонда машҳур бўлди. Кейинчалик олимнинг серқирра фаолияти
            ҳақида юқорида номлари келтирилган турли асарлар яратилди. Мазкур
            асарлар нафақат олимнинг таржимаи ҳоли ва фаолиятини ўрганишда,
            балки, Туркистон жадидлари фаолияти тарихини ўрганишда ҳам муҳим
            аҳамиятга эга бўлди.
          </p>
        </b-card-text>
        </b-col>
        <b-col md="3"></b-col>
      </b-row>
    </b-card>
    <!-- <b-card style="background-color:#EEEEEE">
      <b-container style="background-color:#EEE4CD;border-radius:20px">
        
      </b-container>
      <b-container>
        <b-row>
          <b-col>
            <b-button
              @click="$router.push({ name: 'jadidchilar_info' })"
              variant="outline-danger"
            >
              {{ $t("Back") }}
            </b-button>
          </b-col>
          
        </b-row>
      </b-container>
    </b-card> -->
  </div>
</template>
<script>
export default {};
</script>
<style></style>
